import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';

import { AngularFireAuth } from '@angular/fire/compat/auth';
import { map, take } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import firebase from 'firebase/compat/app';
import 'firebase/auth';

import { UserModal, SetPasswordModal, CreateMicrosoftUserPayload } from './auth.interface';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { LoadingBarState } from '@ngx-loading-bar/core/loading-bar.state';
import secrets  from '../../../secrets.json';
import { authErrorMessages } from '../shared/errors/error-messages';
import { config } from '../shared/constants/config';
import { MatDialog } from '@angular/material/dialog';

@Injectable()
export class AuthService {
  loader: LoadingBarState = this.loadingBarService.useRef();
  authErrors = authErrorMessages;

  constructor(
    private router: Router,
    private afAuth: AngularFireAuth,
    private toastr: ToastrService,
    private http: HttpClient,
    private loadingBarService: LoadingBarService,
    private dialogRef: MatDialog
  ) {
  }

  RegisterUser(user: UserModal): Promise<any> {
    return this.http.post(`users/`, user).toPromise();
  }

  setUserPassword(password: SetPasswordModal): Promise<any> {
    return this.http.post(`users/password`, password).toPromise();
  }

  forgotPassword(email: string): Promise<any> {
    return this.http.post(`users/password/forgot`, email).toPromise();
  }

  SignIn(email: string, password: string) {
    firebase.auth().tenantId = secrets.tenantId;
    return this.afAuth.signInWithEmailAndPassword(email, password);
  }

  SendVerificationMail() {
    return this.afAuth.currentUser
      .then((u: any) => u.sendEmailVerification())
      .then(() => {
        this.toastr.success('Verification Email sent successfully');
        this.router.navigate(['auth/change-password']);
      });
  }

  updatePassword(newPassword: string) {
    return this.afAuth.currentUser
      .then((u: any) => u.updatePassword(newPassword))
      .then(() => {
        this.toastr.success('Password updated successfully');
      })
      .catch((error) => {
        this.toastr.error(error);
      });
  }

  reAuthenticateAndupdatePassword(newPassword: string, oldPassword: string) {
    return this.afAuth.currentUser
      .then((user) => {
        if (!user) {
          return Promise.reject('User not authenticated.');
        }
        const credentials = firebase.auth.EmailAuthProvider.credential(
          user?.email || '',
          oldPassword,
        );
        return user.reauthenticateWithCredential(credentials);
      })
      .then(() => this.updatePassword(newPassword))
      .catch((error) => {
        this.toastr.error(error);
      });
  }

  SignOut() {
    return this.afAuth.signOut().then(() => {
      localStorage.removeItem('configurations');
      this.router.navigate(['/auth/login']);
    });
  }

  logoutFromBE(previousToken = ''): Promise<any> {
    this.dialogRef.closeAll();
    const token = `/${config.magicLink.token}${previousToken}`;
    return this.http.post(`users/logout${previousToken ? token : ''}`, '').toPromise();
  }

  getCurrentUser() {
    const user = this.afAuth.authState
      .pipe(
        take(1),
        map((user) => {
          if (user) {
            return JSON.parse(JSON.stringify(user));
          } else {
            return null;
          }
        }),
      )
      .toPromise();

    return user;
  }

  getTokenRes() {
    const token = this.afAuth.authState
      .pipe(
        take(1),
        map((token) => {
          if (token) {
            return token.getIdTokenResult();
          } else {
            return null;
          }
        }),
      )
      .toPromise();

    return token;
  }

  refreshToken() {
    const token = this.afAuth.authState
      .pipe(
        take(1),
        map((user) => {
          if (user) {
            return user.getIdToken();
          } else {
            return null;
          }
        }),
      )
      .toPromise();

    return token;
  }

  RegisterUserWithMicrosoft(payload : CreateMicrosoftUserPayload): Promise<any> {
    const param: FormData = new FormData();
    param.append('user', JSON.stringify(payload));
    return this.http.post(`users/sso`, param).toPromise();
  }

  microsoftSignIn() {
    const provider = this.getMicrosoftProvider();
    return firebase.auth().signInWithPopup(provider)
    .then((result) => {
      return result;
    })
    .catch((error) => {
      const errorMessage = this.authErrors[error.code];
      if (error.code === 'auth/account-exists-with-different-credential') {
        this.toastr.error(errorMessage? `${errorMessage} please login with existing credentials to link account` : error.message);
        this.router.navigateByUrl('auth/login?q=link-account');
      } else {
        this.toastr.error(errorMessage? errorMessage : error.message);
      }
    });
  }

  getMicrosoftProvider() {
    firebase.auth().tenantId = secrets.tenantId
    const provider = new firebase.auth.OAuthProvider('microsoft.com');
    provider.setCustomParameters({
      tenant: secrets.microsoftTenantId
    });
    return provider;
  }

}
