// eslint-disable-next-line
export const ROUTE = {
  DASHBOARD: '/',
  ANALYTICS: 'analytics',
  PERFORMANCE: 'performance',
  ORDERS: 'orders',
  MESSAGES: 'messages',
  RULES: 'rules',
  CLIENTPROFILE: 'client-profile',
  USERS:'users',
  SETTINGS: 'settings',
  NOTIFICATIONS: 'notifications',
  COMPUTERVISION: 'computer-vision',
  OCR: 'OCR',
  ROLEMANAGEMENT: 'role-management',
  REVIEWTIER: 'review-tier',
  FORMSEQUENCE: 'form-sequence',
  RELEASENOTES: 'release-notes'
};
